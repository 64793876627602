<template>
  <v-dialog v-model="dialog" max-width="800" scrollable persistent>
      <v-card>

        <v-toolbar dark>
          <v-card-title class="headline">
            {{ edit ? 'Update': 'Add'}} request for {{ typeName }}
            <hr>
          </v-card-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon  @click="cancelForm">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

          <v-card-text style="max-height: 500px">
              <v-row>

                  <v-col cols="12">
                      <v-select
                          label="Request category"
                          :items="generalRequestCategories"
                          item-text="title"
                          item-value="id"
                          v-model="generalRequest.request_category_id"
                          outlined
                          dense
                      >
                      </v-select>
                      <span class="text-danger" v-if="$v.generalRequest.request_category_id.$error">This field is required</span>
                  </v-col>

                  <v-col cols="12">
                      <v-text-field
                          label="Title"
                          v-model="generalRequest.title"
                          outlined
                          dense
                      >
                      </v-text-field>
                      <span class="text-danger" v-if="$v.generalRequest.title.$error">This field is required</span>
                  </v-col>

                  <v-col cols="12">
                      <label for="description">Description</label>
                      <ckeditor id="description" v-model="generalRequest.description"
                                :config="editorConfig"></ckeditor>
                      <span class="text-danger" v-if="$v.generalRequest.description.$error">Description is required</span>
                  </v-col>

                  <v-col md="4" cols="12">
                      <v-file-input
                              label="Attachment 1"
                              prepend-inner-icon="mdi-file"
                              prepend-icon=""
                              outlined
                              v-model="generalRequest.attachment_one"
                              dense
                      ></v-file-input>
                  </v-col>

                  <v-col md="4" cols="12">
                      <v-file-input
                              label="Attachment 2"
                              outlined
                              prepend-inner-icon="mdi-file"
                              prepend-icon=""
                              v-model="generalRequest.attachment_two"
                              dense
                      ></v-file-input>
                  </v-col>

                  <v-col md="4" cols="12">
                      <v-file-input
                              label="Attachment 3"
                              outlined
                              prepend-inner-icon="mdi-file"
                              prepend-icon=""
                              v-model="generalRequest.attachment_three"
                              dense
                      ></v-file-input>
                  </v-col>
              </v-row>

              <v-row
                v-if="
                  generalRequest.attachment_1 ||
                  generalRequest.attachment_2 ||
                  generalRequest.attachment_3
                "
            >
              <v-col cols="12"> Attachments </v-col>
              <v-col cols="12">
                <v-list-item-group color="primary">
                  <v-list-item v-if="generalRequest.attachment_1">
                    <v-list-item-icon>
                      <v-icon> mdi-file</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        <a
                            :href="generalRequest.attachment_one_path['real']"
                            target="_blank"
                        >Attachment -
                          {{ generalRequest.attachment_1 }}</a
                        >
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="generalRequest.attachment_2">
                    <v-list-item-icon>
                      <v-icon> mdi-file</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        <a
                            :href="generalRequest.attachment_two_path['real']"
                            target="_blank"
                        >Attachment -
                          {{ generalRequest.attachment_2 }}</a
                        >
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="generalRequest.attachment_2">
                    <v-list-item-icon>
                      <v-icon> mdi-file</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        <a
                            :href="generalRequest.attachment_three_path['real']"
                            target="_blank"
                        >Attachment -
                          {{ generalRequest.attachment_2 }}</a
                        >
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-col>
            </v-row>

          </v-card-text>
        <v-card-actions class="mt-15">
          <v-spacer></v-spacer>
          <v-btn depressed @click="cancelForm" class="text-gray btn btn-standard cancel-btn">
            Cancel
          </v-btn>
          <v-btn depressed :loading="isBusy" @click="createOrUpdate"
                 class="text-white ml-2 btn btn-primary ">
            {{ edit ? "Update" : "Save" }}
          </v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>
<script>
import {required} from "vuelidate/lib/validators";
import UserService from "@/core/services/user/UserService";
import GeneralRequestCategoryService from "@/core/services/general-request/category/GeneralRequestCategoryService";
import GeneralRequestService from "@/core/services/general-request/GeneralRequestService";

const generalRequestCategory = new GeneralRequestCategoryService();
const generalRequest = new GeneralRequestService();
const userService = new UserService();

export default {
  name: 'create-general-request',
  data() {
      return {
          users: [],
          generalRequestCategories: [],
          dialog: false,
          edit: false,
          isBusy: false,
          attrs: false,
          leave_from: false,
          leave_to: false,
          searchByStudents: null,
          editorConfig: {
            versionCheck: false,
            toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
          },
          generalRequest: {
            title: null,
            student_id: null,
            request_category_id: null,
            description: null,
            attachment_one: null,
            attachment_two: null,
            attachment_three: null,
            status: 'received',
          },
      };
  },
  validations: {
    generalRequest: {
      title: {required},
      student_id: {required},
      request_category_id: {required},
      description: {required},
    }
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
    type() {
      return this.$route.params.type || '';
    },
    typeName() {
      return this.type.replace('_', ' ');
    },
  },

  methods: {
    showModal(item = null) {
      this.resetForm();
      if (item) {
          this.edit = true;
          this.generalRequest = item
      }
      this.dialog = true;
      this.getAllRequestCategory();
    },

    hideModal() {
      this.dialog = false;
    },

    getAllRequestCategory() {
        generalRequestCategory.getAllRequestCategory(this.type).then(response => {
            this.generalRequestCategories = response.data.data
        }).catch(() =>{});
    },

    generateFd() {
        let fd = new FormData();
        for (let key in this.generalRequest) {
            if (key == "attachment_one" && this.generalRequest["attachment_one"]) {
                fd.append("attachment_one", this.generalRequest['attachment_one']);
            } else if (key == "attachment_two" && this.generalRequest["attachment_two"]) {
                fd.append("attachment_two", this.generalRequest['attachment_two']);
            } else if (key == "attachment_three" && this.generalRequest["attachment_three"]) {
              fd.append("attachment_three", this.generalRequest['attachment_three']);
            } else {
                if (this.generalRequest[key] != null) {
                    fd.append(key, this.generalRequest[key]);
                }
            }
        }
        return fd;
    },

    createOrUpdate() {
      this.generalRequest.student_id = this.currentUser.id;
        this.$v.generalRequest.$touch();
        if (this.$v.generalRequest.$error) {
            setTimeout(() => {
                this.$v.generalRequest.$reset();
            }, 3000);
        } else {
                let fd = this.generateFd();
            if (this.edit) {
                this.__update(fd);
            } else {
                this.__create(fd);
            }
        }
    },

    __update(fd) {
        this.isBusy = true;
        generalRequest
            .update(this.generalRequest.id, fd)
            .then(response => {
                this.isBusy = false;
                if(response.data.status == "OK") {
                  this.$snotify.success("Information updated");
                  this.$emit('refresh');
                  this.hideModal();
                  this.resetForm();
                }
            })
            .catch(() => {
                this.isBusy = false;
                this.$snotify.error("Something Went Wrong");
            });
    },

    __create(fd) {
        this.isBusy = true;
        generalRequest
            .store(fd)
            .then((response) => {
              this.isBusy = false;
              if(response.data.status == "OK") {
                this.hideModal();
                this.resetForm();
                this.$emit('refresh');
                this.$snotify.success("Leave request added");
              }
            })
            .catch(() => {
                this.isBusy = false;
                this.$snotify.error("Unable to create leave request. Please contact admin");
            });
    },

    resetForm() {
        this.$v.generalRequest.$reset();
        this.edit = false;
        this.generalRequest = {
            title: null,
            request_category_id: null,
            description: null,
            attachment_one: null,
            attachment_two: null,
            attachment_three: null,
            status: 'received',
        };
    },

    cancelForm(){
        this.resetForm();
        this.hideModal();
    }
  }
};
</script>
