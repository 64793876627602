import AcademicClassStudentService from "@/core/services/academic-class/class-student/AcademicClassStudentService";
const studentClassService = new AcademicClassStudentService();
export  default  {

    data(){
        return {
            student_classes:[]
        }
    },
    computed: {
        groupName() {
            let origin = window.location.origin;
            if(origin.includes('malpiins')) {
                return 'Clubs';
            }
            return 'Group'
        },
        isStudentPresident() {
           return this.$store.getters.getIsPresident;
        }
    },
    methods:{
        _getStudentCourses(){
            studentClassService.getStudentClass().then(response=>{
                this.student_classes = response.data
            }).catch(error=>{
                this.$snotify.error('Something went wrong')
            })
        }
    }
}
