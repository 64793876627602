import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;

export default class GeneralRequestCategoryService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}user/student/general-request-category`;
    }

    getAllRequestCategory(type) {
        let url = `${this.#api}/get/all/${type}`
        return apiService.get(url);
    }

    getAllRequestCategoryMenu() {
        let url = `${this.#api}/get/menu`
        return apiService.get(url);
    }
}
